import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Player } from "@lottiefiles/react-lottie-player"

const pageTitle = "خدمات تخصصی ژنراتورهای دیزلی و گازی"

const ServicesPage = () => (
  <Layout>
    <Seo
      title={pageTitle}
      description="ارائه خدمات تخصصی ژنراتورهای دیزلی و گازی توسط شرکت
    مشارکت نیرو آریا کیش"
    />
    <div className="container px-0 sm:px-2">
      <section
        id="services-hero"
        className="grid grid-cols-2 px-5 sm:grid-cols-1 items-center  sm:py-10"
      >
        <div
          id="hero-animation"
          className="border rounded-md p-5 m-5 sm:px-1 sm:m-1 shadow-lg shadow-gray-300"
        >
          <Player
            autoplay
            loop
            src="https://assets6.lottiefiles.com/packages/lf20_EHugAD.json"
          ></Player>
        </div>
        <div className="flex flex-col items-center space-y-6">
          <h1 className="text-3xl sm:text-3xl font-semibold text-primary">
            {pageTitle}
          </h1>
          <h2 className="text-2xl sm:text-xl font-semibold text-primary">
            با تکیه بر اصل مشتری مداری، همواره تلاش کرده ایم تا با ارائه خدمات
            پس از فروش مناسب، رضایت خاطر مشتریان محترم را فراهم آوریم.
          </h2>
          <p className="text-lg">
            ارائه پشتیبانی مناسب و خدمات پس از فروش مربوطه نظیر آموزش، تامین
            قطعات مصرفی و یدکی، باعث می شود که مشتریان این شرکت، افتخار همراهی
            همیشگی را به شرکت مشارکت نیرو بدهند. ما از ابتدا تا انتهای راه،
            همراه باوفای شما خواهیم بود.
          </p>
          <button
            className="px-10 py-4 bg-secondary text-xl text-white max-w-max rounded shadow-lg shadow-black hover:bg-primary"
            onClick={() => {
              navigate("/contact-us")
            }}
          >
            تماس با ما
          </button>
        </div>
      </section>
      <section
        id="services-details"
        className="grid grid-cols-2 sm:grid-cols-1 items-center  py-10 sm:px-2 bg-primary"
      >
        <div className="flex flex-col items-center space-y-6 p-10 sm:p-5">
          <h3 className="text-3xl font-medium text-white">
            طراحی مهندسی نیروگاههای دیزلی و گازی
          </h3>
          <p className="text-lg font-normal text-white">
            پیاده سازی نیروگاه دیزلی یا گازی امری پیچیده است که طراحی در آن حرف
            اول را میزند. عمده نگرانی مشتریان بعد از اصالت موتورژنراتورها و
            ضمانت تامین قطعات یدکی اصلی برای موتورژنراتورهای دیزلی و گاز سوز،
            نحوه طراحی نیروگاه دیزلی و یا گازی می باشد که از اهمیت ویژه ای
            برخوردار می باشد.
          </p>
          <button
            className="px-10 py-4 bg-secondary text-xl text-white max-w-max rounded"
            onClick={() => {
              navigate("/services")
            }}
          >
            ملاحظه خدمات
          </button>
        </div>
        <div id="designGenerators" className="p-10 sm:p-5">
          <StaticImage
            src="../images/services/design-diesel-generators.jpg"
            alt="Design Diesel Generators"
          />
        </div>

        <div id="supplyGenerators" className="p-10 sm:p-5">
          <StaticImage
            src="../images/services/designing-monitoring-system-of-diesel-generator-set.jpg"
            alt="Supply Diesel Generators"
          />
        </div>
        <div className="flex flex-col items-center space-y-6 p-10 sm:p-5">
          <h3 className="text-3xl font-medium text-white">
            تامین موتور ژنراتور، نصب و راه اندازی و بهره برداری
          </h3>
          <p className="text-lg font-normal text-white">
            عمده تمرکز شرکت مشارکت نیرو بر تامین انواع موتورژنراتورهای دیزلی از
            معتبر ترین برندهای جهانی مانند Cummins Power و VOLVO و همچنین تامین
            موتورژنراتورهای گازسوز LIEBHERR می باشد.
          </p>
          <button
            className="px-10 py-4 bg-secondary text-xl text-white max-w-max rounded"
            onClick={() => {
              navigate("/services")
            }}
          >
            ملاحظه خدمات
          </button>
        </div>

        <div className="flex flex-col items-center space-y-6 p-10 sm:p-5">
          <h3 className="text-3xl font-medium text-white">
            تامین قطعات یدکی و مصرفی ژنراتورها
          </h3>
          <p className="text-lg font-normal text-white">
            قطعات یدکی اصلی متضمن کارکرد پیوسته و بدون نقص می باشند. تامین قطعات
            یدکی و مصرفی یک پروژه بدون در نظر گرفتن توان آن از اهمیت بسیار
            بالایی برخوردار است. اهمیت این موضوع صرفا در تامین قطعات یدکی اصلی و
            تامین آن از سازنده موتورزنراتورها می باشد چرا که تامین قطعات غیر
            اصلی از هر سازنده ای برای همه فروشندگان وجود دارد.
          </p>
          <button
            className="px-10 py-4 bg-secondary text-xl text-white max-w-max rounded"
            onClick={() => {
              navigate("/services")
            }}
          >
            ملاحظه خدمات
          </button>
        </div>
        <div id="GeneratorSpareParts" className="p-10 sm:p-5">
          <StaticImage
            src="../images/services/generator-spare-parts.jpg"
            alt="Generator Spare Part"
          />
        </div>

        <div id="generatorMaintanaceService" className="p-10 sm:p-5">
          <StaticImage
            src="../images/services/generator-maintenance-service.jpg"
            alt="Generator Maintanace Service"
          />
        </div>
        <div className="flex flex-col items-center space-y-6 p-10 sm:p-5">
          <h3 className="text-3xl font-medium text-white">
            انجام سرویسهای دوره ای ژنراتورها
          </h3>
          <p className="text-lg font-normal text-white">
            سرویسهای دوره ای یک موتورژنراتور و یا یک نیروگاه از مهم ترین عوامل
            در میزان تولید برق پایدار و با کیفیت می باشد. استفاده از قطعات اصلی
            و تعویض این قطعات در زمان های اعلام شده می تواند خروجی پایدار یک
            موتورژنراتور و یا یک نیروگاه را تضمین نماید.
          </p>
          <button
            className="px-10 py-4 bg-secondary text-xl text-white max-w-max rounded"
            onClick={() => {
              navigate("/services")
            }}
          >
            ملاحظه خدمات
          </button>
        </div>
      </section>
    </div>
  </Layout>
)

export default ServicesPage
